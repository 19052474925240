import {observer} from "mobx-react";
import Banner from '../../assets/img/banner.png';
import PropertySale from '../../assets/img/property-sale.png';
import HelpRealtors from '../../assets/img/help-vector.png';
import Brokerages from '../../assets/img/brokerages.png';
import Button from "../../common/components/button";
import './index.scss'
import {Testimonials} from "../../common/components/Testimonials";
import {FeaturesList} from "../../common/components/Features";
import {DemoWebsites} from "../../common/components/DemoWebsites";
import {AccountCreationStore} from "../../stores/accountCreation.store";
import {FAQ} from "../../common/components/Faq";
import {ParallaxBox} from "../../common/components/ParallaxBox";
import React from "react";
import NavLink from "../../common/components/NavLink";

const Home = observer((props) => {
    return <div className=" pt-5 mt-5  ">
        <div className="container">
            <div className="row align-items-sm-center">
                <div className="col-12 order-1 order-lg-0 col-md-6  mt-5 mt-md-0">
                    <h1><span>We Get You The <span className="bg-primary text-white px-3 rounded">Leads</span></span>
                    </h1><h1>So You Focus More On <span
                    className="bg-success text-white px-3 rounded">Sales</span></h1>
                    <h5 className="pt-1 pb-3" style={{fontWeight: 'normal'}}>We
                        help <span><strong>Realtors</strong></span> build
                        their <span><strong>Strong Online Presence</strong></span></h5>
                    <div className="d-flex gap-3">
                        <Button title={"Get Started for FREE"} type="solid" href="/auth/register"/>
                    </div>

                </div>
                <div className="col-12 order-0 order-lg-1 col-md-6 ">
                    <img src={Banner} className="img img-fluid"/>
                </div>
            </div>
        </div>
        <FeaturesList/>
        <div className="container mt-5">
            <div className="row  align-items-center">
                <div className="col-12 col-md-6 pt-5 px-3">
                    <img src={HelpRealtors} className="img img-fluid" alt=""/>
                </div>
                <div className="col-12 col-md-6 px-3 order-1 order-md-0 mt-5 mt-md-0">
                    <h3>We help <span>Realtors</span> build their strong online presence</h3>
                    <p>We provide a beautifully designed professional website to real estate agents and
                        brokers, dealing in commercial or/and residential properties. Your website will
                        have all the important features needed for a strong web presence.</p>
                    <Button title={"Get Started For Free"} href="/auth/register"/>
                </div>
            </div>
        </div>
        <div className="container mt-3 mt-lg-5">
            <div className="row  align-items-center">
                <div className="col-12 col-md-6 px-3 order-1 order-md-0 mt-5 mt-md-0">
                    <h3><span>Customized</span> website to cater to all your needs!</h3>
                    <p>Need to provide the best user experience to your clients? We've got your back!
                        The website will be user-friendly, have intuitive navigation and responsive design,
                        multiple listing services access, and much more!</p>
                    <Button title={"See Plans"} href="/pricing"/>

                </div>
                <div className="col-12 col-md-6 pt-5 px-3 order-0 order-md-1">
                    <img src={PropertySale} className="img img-fluid" alt=""/>
                </div>
            </div>
        </div>
        <DemoWebsites/>
        <Testimonials testimonials={AccountCreationStore.testimonials}/>
        <div className="py-3">
            <img src={Brokerages} alt={"brokerages"} className={"img img-fluid mx-auto d-flex brokerages py-5"}/>
        </div>
        <FAQ list={AccountCreationStore.faq}/>
        <ParallaxBox>
            <div className="row">
                <div className="col-12 text-center">
                    <h3 className="display-6">Let's get started!</h3>
                    <h3 className="h5 py-3" style={{fontWeight: '300'}}>Start your <strong>FREE TRIAL</strong> & assisted setup.
                        <br/>
                    </h3>
                    <NavLink to="/auth/register" className="btn btn-outline-light btn-lg">Let's get started!</NavLink>
                </div>
            </div>
        </ParallaxBox>
        {/*<div className="container-fluid mt-5 theme-bg">
            <div className="row py-5 text-center">
                <div className="col-12 col-sm-8 col-md-6 mx-auto">
                    <h3 className="header-2 underline"><span>Customized</span> website to cater to all your needs!</h3>
                    <p>The website will be user-friendly, have intuitive navigation and responsive design,
                        multiple listing services access, and much more!</p>
                    <div className="row">
                        <div className="col-12 col-lg-8 mx-auto">
                            <div className="input-group ">
                                <input type="text" placeholder="Email Address" className="form-control"/>
                                <Button title="Submit"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>*/}
    </div>
});

export default Home;
