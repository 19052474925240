import React, {createRef, useEffect} from 'react';
import Logo from '../../../assets/img/logo.png';
import Button from "../button";
import {Formik} from "formik";
import * as Yup from "yup";
import ReCAPTCHA from "react-google-recaptcha";
import {contactUs} from "../../../pages/Authentication/auth.api";
import {ToasterStore} from "../../../stores/toaster.store";
import NavLink from "../NavLink";

const footerContactSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
    contact: Yup.string().min(10, 'Invalid contact number').required('Contact is required'),
    recaptcha: Yup.string().required('Recaptcha required.')
});
const Footer = (props) => {
    const _reCaptchaRef = createRef();
    useEffect(function () {
        _reCaptchaRef.current.execute();
    }, []);

    async function submitContact(values, {setSubmitting, resetForm}) {
        const response = await contactUs({
            message: 'Footer callback form',
            email: values.email,
            phone: values.contact,
            full_name: 'Anonymous',
            recaptcha: values.recaptcha
        });
        if (!response.api_status) {
            ToasterStore.setSuccessMessage('Message sent successfully. We will contact you as early as possible.');
        } else {
            ToasterStore.setErrorMessage(response.message)
        }
        _reCaptchaRef.current.execute();
        setSubmitting(false);
        resetForm();
    }

    return <footer className="theme-bg small">
        <div className="container py-lg-5 py-4 ">
            <div className="row">
                <div className="col-md-3 col-12 order-md-1 order-1 mb-md-0 mb-4">
                    <ul className="nav nav-light flex-column">
                        <li className="nav-item mb-2">
                            <img src={Logo} alt="" width={75}/>
                        </li>
                        <li className="nav-item mb-2">
                            <p className="">We get you the leads so you focus more on sales.
                                We help Realtors build their strong online presence
                            </p>
                        </li>
                        <li className="nav-item mb-2">
                            <a href="#" className="nav-link p-0 font-weight-normal">
                                <span className=" bi-house"> </span>
                                <span className="text-break"/>
                            </a>
                        </li>
                        <li className="nav-item mb-2">
                            <a href="tel:{{website_config.footer_config.company_contact}}"
                               className="nav-link p-0 font-weight-normal">
                                <span className="bi-phone"> </span>
                                <span
                                    className="text-break"/>
                            </a>
                        </li>
                        <li className="nav-item mb-2">
                            <a href="fax:{{website_config.footer_config.company_fax}}"
                               className="nav-link p-0 font-weight-normal">
                                <span className="bi-telephone"> </span>
                                <span
                                    className="text-break"/>
                            </a>
                        </li>
                        <li className="nav-item mb-2">
                            <a href="mailto:{{website_config.footer_config.company_email}}"
                               className="nav-link p-0 font-weight-normal">
                                <span className=" bi-envelope"> </span>
                                <span className="text-break"/>
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="col-md-3 col-12 order-md-3 order-3 mb-md-0 mb-4">
                    <h3 className="h6 text-uppercase ">Quick links</h3>
                    <ul className="nav nav-light flex-md-column flex-sm-row flex-column">
                        <li className="nav-item mb-2">
                            <NavLink to="/" className="nav-link p-0 font-weight-bold text-muted">
                                <span className="text-break">Home</span>
                            </NavLink>
                        </li>
                        <li className="nav-item mb-2">
                            <NavLink to="/designs" className="nav-link p-0 font-weight-bold text-muted">
                                <span className="text-break">Designs</span>
                            </NavLink>
                        </li>
                        <li className="nav-item mb-2">
                            <NavLink to="/plans" className="nav-link p-0 font-weight-bold text-muted">
                                <span className="text-break">Plans</span>
                            </NavLink>
                        </li>
                        <li className="nav-item mb-2">
                            <NavLink to="/contact" className="nav-link p-0 font-weight-bold text-muted">
                                <span className="text-break">Contact</span>
                            </NavLink>
                        </li>
                        <li className="nav-item mb-2">
                            <NavLink to="/how-to-setup/list" className="nav-link p-0 font-weight-bold text-muted">
                                <span className="text-break">How to Setup</span>
                            </NavLink>
                        </li>
                    </ul>
                </div>
                <div className="col-md-3 col-12 order-md-3 order-3 mb-md-0 mb-4">
                    <h3 className="h6  text-uppercase ">Follow Us</h3>
                    <ul className="nav nav-light flex-md-column flex-sm-row flex-column">
                        <li className="nav-item mb-2">
                            <a href="https://www.facebook.com/agentedgesolutions"
                               target={"_blank"}
                               className="nav-link p-0 font-weight-bold text-muted">
                                <span className="text-break">Facebook</span>
                            </a>
                        </li>
                        <li className="nav-item mb-2">
                            <a href="/" className="nav-link p-0 font-weight-bold text-muted">
                                <span className="text-break">Linkedin</span>
                            </a>
                        </li>
                        <li className="nav-item mb-2">
                            <a href="/" className="nav-link p-0 font-weight-bold text-muted">
                                <span className="text-break">Twitter</span>
                            </a>
                        </li>
                        <li className="nav-item mb-2">
                            <a href="https://www.instagram.com/agentedgesolutions/"
                               target={"_blank"}
                               className="nav-link p-0 font-weight-bold text-muted">
                                <span className="text-break">Instagram</span>
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="col-md-3 col-sm-6 order-md-4  order-4 mb-md-0 mb-4">
                    <h3 className="h6 mb-3 text-uppercase ">Get in touch with us</h3>
                    <Formik
                        initialValues={{email: '', contact: ''}}
                        validationSchema={footerContactSchema}
                        onSubmit={submitContact}
                    >
                        {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              isSubmitting,
                              setFieldValue,
                              setSubmitting
                          }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="form-group mt-2">
                                    <input className="form-control form-control-sm" placeholder="john@example.com"
                                           name="email"
                                           onChange={handleChange}
                                           onBlur={handleBlur}
                                           value={values.email}
                                    />
                                    <p className="small text-danger pt-1">{errors.email && touched.email && errors.email}</p>

                                </div>
                                <div className="form-group ">
                                    <input className="form-control form-control-sm" placeholder="9876543210"
                                           type="text"
                                           name="contact"
                                           onChange={handleChange}
                                           onBlur={handleBlur}
                                           value={values.contact}
                                    />
                                    <p className="small text-danger pt-1">{errors.contact && touched.contact && errors.contact}</p>

                                </div>
                                <ReCAPTCHA
                                    ref={_reCaptchaRef}
                                    sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                                    onChange={(value) => {
                                        setFieldValue("recaptcha", value);
                                        setSubmitting(false);
                                    }}
                                    size="invisible"
                                />
                                <Button title={"Get a Callback"} type="solid"
                                        isSubmit={true}
                                        className=" btn-block w-100" disabled={isSubmitting}/>
                            </form>
                        )}
                    </Formik>

                </div>
            </div>
            <div className="row">
                <div className="col-12 text-center  d-flex justify-content-center gap-3">
                    <NavLink className={'nav-link p-0'} to="/policies/privacy-policy">Privacy Policy</NavLink> |
                    <NavLink className={'nav-link  p-0'} to="/policies/cookie-policy">Cookie Policy</NavLink> |
                    <NavLink className={'nav-link  p-0'} to="/faq">FAQ</NavLink>
                    <span className="text-primary">&copy; Copyright 2022  AgentEdge inc. All rights reserved</span>
                </div>
            </div>

        </div>
    </footer>
};

export default Footer;
