
import React, {useState} from 'react'
import {PlanPricingImage, StarIcon, SuccessSignupImage} from "../../utils/images-export";
import {useNavigate} from 'react-router-dom';
import {AccountCreationStore} from "../../stores/accountCreation.store";
import {Loading} from "../../common/components/Loading";
import {observer} from "mobx-react";
import {FAQ} from "../../common/components/Faq";
import {ContactQuery} from "../../common/components/ContactQuery";
import {PricingInfo} from "../../common/components/PricingInfo";

const Pricing = observer(() => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [pricing, setPricing] = useState(12);

    const getDiscountedValue = ({plan, discount}) => {
        return (plan * ((1 - discount / 100))).toFixed(0);
    };
    const register = (plan) => {
        AccountCreationStore.setSelectedPlan(plan);
        return navigate('/auth/register')
    };
    return AccountCreationStore.plansLoading ?
        <>
            <Loading/>
        </>
        :
        <div className="py-my">
            <div className="container-fluid  mt-5">
                <div className="container">
                    <div className="text-center">
                        <h3 className="underline"><span>Plans & Pricing</span></h3>
                        <p>Our customized plans for all your real estate needs.</p>
                    </div>
                    <div className="row pt-4">
                        <div className="col-12 col-lg-4 d-flex flex-column justify-content-center">
                            <img src={SuccessSignupImage} className="img img-fluid mx-auto d-none d-lg-block" alt=""/>

                            <h3>You are all Covered</h3>
                            <p>Get the freedom to manage all your leads, emails, and ads in one place.</p>
                            <div className="d-flex flex-column  mt-3">
                                <p className="d-flex gap-2 svg-shadow"><img width={35} src={StarIcon}
                                                                            className="svg-shadow"
                                                                            alt=""/>30 Days free Trial</p>
                                <p className="d-flex gap-2 svg-shadow"><img width={35} src={StarIcon} alt=""/>Cancel
                                    Anytime
                                </p>
                                <p className="d-flex gap-2 svg-shadow"><img width={35} src={StarIcon} alt=""/>Happy
                                    Customers</p>
                                <p className="d-flex gap-2 svg-shadow"><img width={35} src={StarIcon} alt=""/>Lead
                                    generating websites
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-8 col-12">

                            <PricingInfo/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mt-5">
                <div className="row  align-items-center">

                    <div className="col-12 col-lg-6 px-3 order-1 order-lg-0 mt-5 mt-lg-0">
                        <h3>Your lead generating <span>Real Estate</span> website is one click away</h3>
                        <p>Need to provide the best user experience to your clients? We've got your back!
                            The website will be user-friendly, have intuitive navigation and responsive design,
                            multiple listing services access, and much more!</p>

                    </div>
                    <div className="col-12 col-lg-6 pt-5 px-3 order-0 order-lg-1">
                        <img src={PlanPricingImage} className="img img-fluid mx-auto d-block" alt=""/>
                    </div>
                </div>
            </div>
            <ContactQuery/>
            <FAQ list={AccountCreationStore.faq}/>
        </div>

});
export default Pricing;
